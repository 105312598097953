<template>
  <div class="md-layout">
    <div class="md-layout-item md-size-66 md-xsmall-size-80 mx-auto">
      <md-card
        class="md-theme-default md-card-wizard active"
        style="min-height: unset;"
      >
        <ValidationObserver ref="form">
          <form @submit.prevent="validate">
            <md-card-header class="md-card-header text-center">
              <h3 class="title">
                Departments Form
              </h3>
              <h5 class="category">
                Complete the form.
              </h5>
            </md-card-header>

            <md-card-content>
              <div class="md-layout">
                <div class="md-layout-item md-size-100">
                  <form-text
                    v-model="model.name"
                    name="name"
                    rules="required"
                    icon="face"
                  />
                </div>
              </div>
            </md-card-content>
            <md-card-actions md-alignment="space-between">
              <md-button
                class="btn-previous"
                @click.native="$router.back()"
              >
                Cancel
              </md-button>
              <md-button
                class="md-success"
                @click="saveDepartment"
              >
                Save
              </md-button>
            </md-card-actions>
          </form>
        </ValidationObserver>
      </md-card>
    </div>
  </div>
</template>
<script>
import Swal from 'sweetalert2';
import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { FormText } from '@/components/Inputs';

extend('required', required);

export default {
  components: {
    FormText,
  },
  data() {
    return {
      model: {
        name: '',
      },
    };
  },
  mounted() {
    if (this.$route.name === 'EditDepartment') {
      this.getDepartment(this.$route.params.id);
    }
  },
  methods: {
    validate() {
      return this.$refs.form.validate().then((res) => res);
    },
    saveDepartment() {
      if (this.validate()) {
        const { id } = this.$route.params;

        if (id == null) {
          this.request(`${this.$API.DEPARTMENTS}`, 'post', this.model, () => {
            Swal.fire({
              title: 'Good job!',
              text: 'New Department has been created!',
              type: 'success',
              confirmButtonClass: 'md-button md-success',
              buttonsStyling: false,
            }).then(() => this.onReturn());
          });
        } else {
          this.request(`${this.$API.DEPARTMENTS}${id}`, 'put', this.model, () => {
            Swal.fire({
              title: 'Good job!',
              text: 'Department has been updated!',
              type: 'success',
              confirmButtonClass: 'md-button md-success',
              buttonsStyling: false,
            }).then(() => this.onReturn());
          });
        }
      }
    },
    // eslint-disable-next-line no-unused-vars
    getDepartment(id) {
      this.request(`${this.$API.DEPARTMENTS}${id}`, 'get', null, ({ data }) => {
        this.$nextTick(() => {
          this.model = {
            name: data.name,
          };
        });
      });
    },
    onReturn() {
      this.$router.push({ name: 'Departments' });
    },
  },
};
</script>
